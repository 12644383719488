import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "big-cards columns is-multiline",
  "data-testid-big-cards": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BigCard = _resolveComponent("BigCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "column is-half-tablet",
        key: entity['@id']
      }, [
        _createVNode(_component_BigCard, {
          class: "mb-6 scroll-transition",
          entity: entity
        }, null, 8, ["entity"])
      ]))
    }), 128))
  ]))
}