
import { defineComponent } from "vue";
import BigCards from "../../shared/components/BigCards.vue";

export default defineComponent({
    name: "EntityList",
    components: { BigCards },
    props: {
        entities: {
            type: Array,
            defualt: () => [],
        },
        label: {
            type: String,
            default: "",
        },
    },
});
