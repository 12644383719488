import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24c9d1e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "periods-list-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityBlockList = _resolveComponent("EntityBlockList")!

  return (_ctx.entities.length)
    ? (_openBlock(), _createBlock(_component_EntityBlockList, {
        key: 0,
        class: "my-6",
        entities: _ctx.entities,
        routerName: "PeriodDetails",
        label: _ctx.$t('period.list')
      }, {
        default: _withCtx(() => [
          _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("period.periods")), 1)
        ]),
        _: 1
      }, 8, ["entities", "label"]))
    : _createCommentVNode("", true)
}