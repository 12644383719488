
import { useScrollTransition } from "@kunsten/core";
import { defineComponent } from "vue";
import BigCard from "./BigCard.vue";

export default defineComponent({
    name: "BigCards",
    components: { BigCard },
    props: {
        entities: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        useScrollTransition();
    },
});
