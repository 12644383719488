
import { usePeriodsResource } from "@kunsten/core";
import { defineComponent } from "vue";
import EntityBlockList from "../../entity/EntityBlockList.vue";

export default defineComponent({
    name: "PeriodsList",
    components: { EntityBlockList },
    setup() {
        const { entities } = usePeriodsResource({
            page: "1",
            pagination: "false",
            "order[sortNumber]": "asc",
        });

        return { entities };
    },
});
