import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c84ec850"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "themes-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityHero = _resolveComponent("EntityHero")!
  const _component_EntityList = _resolveComponent("EntityList")!
  const _component_PeriodsList = _resolveComponent("PeriodsList")!
  const _component_EntityBlockList = _resolveComponent("EntityBlockList")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (!_ctx.loadingOne && !_ctx.loadingTwo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.hero)
          ? (_openBlock(), _createBlock(_component_EntityHero, {
              key: 0,
              entity: _ctx.hero,
              pageHeading: true
            }, null, 8, ["entity"]))
          : _createCommentVNode("", true),
        (_ctx.promotedThemes.length)
          ? (_openBlock(), _createBlock(_component_EntityList, {
              key: 1,
              entities: _ctx.promotedThemes,
              label: _ctx.$t('theme.list')
            }, null, 8, ["entities", "label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_PeriodsList),
        (_ctx.notPromotedThemes.length)
          ? (_openBlock(), _createBlock(_component_EntityBlockList, {
              key: 2,
              class: "my-6",
              entities: _ctx.notPromotedThemes,
              routerName: "ThemeDetails",
              label: _ctx.$t('theme.sublist')
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t("theme.themes")), 1)
              ]),
              _: 1
            }, 8, ["entities", "label"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
}