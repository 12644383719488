
import { computed, defineComponent } from "vue";
import EntityList from "../../entity/EntityList.vue";
import EntityHero from "../../entity/EntityHero.vue";
import EntityBlockList from "../../entity/EntityBlockList.vue";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import { useDocumentTitle, useThemesResource } from "@kunsten/core";
import PeriodsList from "../../periods/views/PeriodsList.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "Themes",
    components: { EntityList, SpinnerContainer, EntityHero, PeriodsList, EntityBlockList },
    setup() {
        const { t } = useI18n();

        useDocumentTitle(t("general.base-title"), t("navigation.themes"));

        const { loading: loadingOne, entities: promoted } = useThemesResource({
            page: "1",
            pagination: "false",
            "order[sortNumber]": "asc",
            promoteToFront: "true",
        });

        const { loading: loadingTwo, entities: notPromotedThemes } = useThemesResource({
            page: "1",
            pagination: "false",
            "order[sortNumber]": "asc",
            promoteToFront: "false",
        });

        const promotedThemes = computed(() => {
            if (promoted.value.length < 2) return [];
            return promoted.value.slice(1, promoted.value.length);
        });

        const hero = computed(() => {
            return promoted.value[0];
        });

        return { loadingOne, loadingTwo, hero, promotedThemes, notPromotedThemes };
    },
});
