import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60807891"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entity-block-list scroll-transition" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "entity-block-list-title mb-5" }
const _hoisted_4 = { class: "columns is-multiline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Block = _resolveComponent("Block")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", {
      class: "entity-block-list-container",
      "aria-label": _ctx.label
    }, [
      _createElementVNode("p", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
          return (_openBlock(), _createElementBlock("div", {
            key: entity['@id'],
            class: "column is-half-tablet is-one-third-desktop"
          }, [
            _createVNode(_component_router_link, {
              to: { name: _ctx.routerName, params: { id: entity.id } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Block, { bold: true }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Translation, {
                      data: entity,
                      property: "title"
                    }, null, 8, ["data"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ], 8, _hoisted_2)
  ]))
}