import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b11cf00"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "page-container pt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BigCards = _resolveComponent("BigCards")!

  return (_openBlock(), _createElementBlock("section", {
    class: "entity-list",
    "aria-label": _ctx.label
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BigCards, { entities: _ctx.entities }, null, 8, ["entities"])
    ])
  ], 8, _hoisted_1))
}