
import { useScrollTransition } from "@kunsten/core";
import { defineComponent } from "vue";
import Block from "../../shared/components/Block.vue";
import Translation from "../../shared/components/Translation.vue";

export default defineComponent({
    name: "EntityBlockList",
    components: { Block, Translation },
    props: {
        entities: {
            type: Array,
            required: true,
        },
        routerName: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
    },
    setup() {
        useScrollTransition();
    },
});
